const appColumns = [
  // {
  //   key: 'DateStats',
  //   label: 'Date',
  //   show: true,
  //   headerTitle: 'Date',
  //   sortable: true,
  //   thStyle: 'width:90px',
  //   summed: false,
  // },
  {
    key: 'Id',
    label: 'ID',
    show: true,
    headerTitle: 'ID',
    sortable: true,
    thStyle: 'width:70px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'Hostname',
    label: 'Hostname',
    show: true,
    headerTitle: 'Hostname',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'EffectiveHostname',
    label: 'Effective Hostname',
    show: true,
    headerTitle: 'Effective Hostname',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'AdsTxtCount',
    label: 'ads.txt Records',
    show: true,
    headerTitle: 'ads.txt Records',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    summed: false,
    thStyle: 'width:160px',
  },
  {
    key: 'Ivt',
    label: 'IVT',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
  },
  {
    key: 'Sivt',
    label: 'SIVT',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
  },
  {
    key: 'DelistedCount',
    label: 'Delisted Count',
    show: true,
    headerTitle: 'Delisted Count',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:130px',
  },
  {
    key: 'CreationDate',
    label: 'Registration Date',
    show: true,
    headerTitle: 'Registration Date',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:150px',
  },
]
export default appColumns
